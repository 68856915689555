import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  
  interface Palette {
    primaryBackground: Palette['primary'];
    secondaryBackground: Palette['primary'];
  }

  interface PaletteOptions {
    primaryBackground: PaletteOptions['primary'];
    secondaryBackground: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

// logo colors:
// directing: up-down and left-right
// 1: #0E477A
// 2: #005893
// 3: #049BD7
// 4: #01C6CA
// 5: #01F8FD
// 6: #96FCFE

// new palette (fresh our LOGO!)
// Navy Blue: #05445E
// Blue Grotto: #189AB4
// Blue Green: #75E6DA
// Baby Blue: #D4F1F4

const rawTheme = createTheme({
  palette: {
    primaryBackground: {
      light: "#f5fffa",
      main: "#96fcfe",
      dark: "#48f8fd",
    },
    secondaryBackground: {
      light: "#D4F1F4",
      // main: "#75E6DA",
      main: "#ffeaff",
      dark: "#189AB4",
    },
    primary: {
      light: '#96FCFE',
      // main: '#0E477A',
      main: '#28282a',
      dark: '#0E477A',
    },
    secondary: {
      light: '#fff5f8',
      main: '#ff3366',
      dark: '#e62958',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Roboto Light
    fontWeightRegular: 400, // Roboto Regular
    fontWeightMedium: 700, // Roboto Bold
    fontWeightBold: 900, // Roboto Black
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: "'Roboto', sans-serif",
  textTransform: 'uppercase',
};

const fontLogo = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightLight,
  fontFamily: "'Roboto', sans-serif",
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    fontLogo,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
    logo: {
      ...fontLogo,
      fontSize: 25,
      textAlign: 'center',
      color: rawTheme.palette.primary.main,
      letterSpacing: 2,
    }
  },
};

export default theme;