import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import reportWebVitals from 'reportWebVitals';
import MainProgress from 'components/MainProgress/MainProgress';

// main wrapper and global index
const RootRouteOutlet = React.lazy(() => import('routes/RootRouteOutlet'));
const MainRoute = React.lazy(() => import('routes/MainRoute'));
// \main wrapper and global index

// portfolio
const PortfolioRouteOutlet = React.lazy(() => import('routes/PortfolioRouteOutlet'));
const PortfolioRoute = React.lazy(() => import('routes/PortfolioRoute'));
const PortfolioViewRoute = React.lazy(() => import('routes/PortfolioViewRoute'));
// \portfolio

const ServicesRoute = React.lazy(() => import('routes/ServicesRoute'));

const EvolutionProgramRoute = React.lazy(() => import('routes/EvolutionProgramRoute'));
const PerfectionProgramRoute = React.lazy(() => import('routes/PerfectionProgramRoute'));
const AboutRoute = React.lazy(() => import('routes/AboutRoute'));

const NotFoundRoute = React.lazy(() => import('routes/NotFoundRoute'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <React.Suspense fallback={<MainProgress />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootRouteOutlet />}>
            <Route index element={<MainRoute />} />
            <Route path="/portfolio/*" element={<PortfolioRouteOutlet />}>
              <Route index element={<PortfolioRoute />} />
              <Route path=":projectId" element={<PortfolioViewRoute />} />
            </Route>
            <Route path="services" element={<ServicesRoute />}>
              {/* <Route path=":serviceId" element={<ServiceRoute />} /> */}
            </Route>
            <Route path="evolution-program" element={<EvolutionProgramRoute />} />
            <Route path="perfection-program" element={<PerfectionProgramRoute />} />
            <Route path="about" element={<AboutRoute />} />
            <Route path="*" element={<NotFoundRoute />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
