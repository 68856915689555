import * as React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

function CustomGlobalStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        body : {
          bgcolor: '#ffffff'
        },
        a: {
          color: theme.palette.primary.dark,
          // '&:visited': {
          //   color: theme.palette.secondary.dark,
          // }
        },
      })}
    />
  );
}

export default CustomGlobalStyles;