import React from 'react';
import {
  LinearProgress,
  Box
} from '@mui/material';
import withRoot from 'hocs/withClearRoot';

type MainProgressProps = {

};

const MainProgress: React.FC<MainProgressProps> = (props: MainProgressProps) => {

  return (
    <Box
      sx={{
        position: 'relative',
        width: 1,
        height: 1
      }}
    >
      <LinearProgress
        color="secondary"
        sx={{
          position: 'absolute',
          width: 1,
          height: '3px',
        }}
      />
    </Box>
  );

};

export default withRoot(MainProgress);